import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={siteTitle} />
      <h2 className="text-gray-500 text-xs uppercase tracking-widest font-medium">
        Currently:
      </h2>
      <p>
        Front-End Developer at{' '}
        <a
          href="https://convertkit.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="font-medium"
        >
          ConvertKit
        </a>
        . Helping creators earn a living online.
      </p>
      <p>
        Creator of{' '}
        <a
          href="https://moonlighterdelighter.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="font-medium"
        >
          Moonlighter Delighter
        </a>
        . Simple time-tracking and invoicing for freelancers.
      </p>
      <h2 className="text-gray-500 text-xs uppercase tracking-widest font-medium">
        Previously:
      </h2>
      <p>
        Full-stack developer at{' '}
        <a
          href="https://dunked.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="font-medium"
        >
          Dunked
        </a>
        . Dedicated to making beautiful online portfolios easy.
      </p>
      <p>
        WordPress theme and plugin developer at ThemeZilla.{' '}
        <Link to="/projects/">View past projects</Link>.
      </p>
      <h2 className="text-gray-500 text-xs uppercase tracking-widest font-medium">
        Connect:
      </h2>
      <p>
        Message me on{' '}
        <a
          href="https://twitter.com/marksouthard"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </a>{' '}
        or <Link to="/blog/">read my blog</Link>.
      </p>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
